import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import HomePage from './HomePage';     // 导入HomePage组件
import Preview1 from './Preview1';
import Preview2 from './Preview2';     // 引入Preview2页面
import Preview5 from './Preview5';     // 
import Preview6 from './Preview6';     // 
import Preview7 from './Preview7';     // 

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />   // 设置HomePage为起始页
        <Route path="/preview1" element={<Preview1 />} /> //设置Preview1页面；
        <Route path="/preview2" element={<Preview2 />} /> //设置Preview2页面；
        <Route path="/Preview5" element={<Preview5 />} /> 
        <Route path="/Preview6" element={<Preview6 />} /> 
        <Route path="/Preview7" element={<Preview7 />} />
      </Routes>
    </Router>
  );
}

export default App;