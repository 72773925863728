import React, { useState, useRef } from 'react';
import html2canvas from 'html2canvas';

function TweetPreview() {
  const [tweetLink, setTweetLink] = useState('');
  const [previewData, setPreviewData] = useState(null);
  const previewContainerRef = useRef(null);

  const handleInputChange = (event) => {
    setTweetLink(event.target.value);
  };

  const fetchOembedData = async () => {
    try {
      const response = await fetch(`https://workers-todo-cold-forest-e8f9.huntvalue.workers.dev/?url=${encodeURIComponent(tweetLink)}`);
      const data = await response.json();

      if (data && data.html) {
        const modifiedHtml = modifyHtmlColor(data.html);
        data.html = modifiedHtml;
      }

      setPreviewData(data);
    } catch (error) {
      console.error('Error fetching oEmbed data:', error);
      setPreviewData(null);
    }
  };

  const modifyHtmlColor = (html) => {
    const modifiedHtml = html.replace(/color:#[A-Fa-f0-9]{6}/g, 'color:#000000');
    const modifiedHtmlWithBlackLinks = modifiedHtml.replace(/<a[^>]*>/g, '<a style="color:#000000;">');
    const modifiedHtmlWithBlackHashtags = modifiedHtmlWithBlackLinks.replace(/<a href="#[^>]*>/g, '<a style="color:#000000;">');
    return modifiedHtmlWithBlackHashtags;
  };

  const downloadPageAsJPG = () => {
    window.scrollTo(0, 0);
    setTimeout(() => {
      const container = previewContainerRef.current;
      html2canvas(container, {
        logging: false,
        useCORS: true
      }).then(function(canvas) {
        const link = document.createElement('a');
        link.download = 'preview.framedpost.donalde.com.jpg';
        link.href = canvas.toDataURL('image/jpeg');
        link.click();
      });
    }, 500);
  };

  return (
    <div style={{ margin: '1px', width: '80vw' }}> {/* Set margin and width as a percentage of the viewport width */}
      <nav style={{ marginTop: '1px', marginBottom: '0px' }}>
        <ul style={{ listStyleType: 'none', padding: 0, display: 'flex', justifyContent: 'center' }}>
         
         {/*【须修改】第一，- 产品标题。*/}
        <li style={{ marginRight: '10px' }}> <a href="#" style={{ textTransform: 'capitalize', textDecoration: 'none' }}>Preview - 

        F1002 - The Framed Post - Golden Frame  </a></li> 

          <li style={{ marginRight: '10px' }}><a href="https://framedpost.donalde.com/chooseastyle.html" target="_blank">More Styles</a></li>
          <li style={{ marginRight: '10px' }}><a href="https://framedpost.donalde.com/f1002.html" target="_blank">Buy it Now</a></li>
        </ul>
      </nav>

      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'relative' }}>
        <div>
          <input
            type="text"
            value={tweetLink}
            onChange={handleInputChange}
            placeholder="e.g. https://twitter.com/matty_pace/status/1777691242127659034"
            style={{ width: '400px' }}
          />
          <button onClick={fetchOembedData}>Preview Now</button>
        </div>
        <div style={{ height: '1px' }}></div>

    <div ref={previewContainerRef} style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '5px' }}>
  <img src="https://raw.githubusercontent.com/filesmake/pics/master/golden-frame-650-540-white-inner-pure-white%20(1).png" alt="Product_Image" />
  {previewData && (
    <div
      style={{
        position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 1,
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
      }}
    >
      <h3 style={{ margin: 0 }}>{previewData.title}</h3>
      <div dangerouslySetInnerHTML={{ __html: previewData.html }} />
    </div>
  )}
</div>

        <li style={{ marginTop: '1px', marginRight: '10px', listStyle: 'none' }}>
          <button 
            onClick={downloadPageAsJPG} 
            style={{
              backgroundColor: 'transparent', // No background color
              color: 'grey', // Font color (161, 101, 186)
              border: 'none', // No border
              textDecoration: 'underline', // Underline text
              cursor: 'pointer' // Change cursor to hand
            }}>
            Download JPG
          </button>
        </li>
      </div>
    </div>
  );
}

export default TweetPreview;
