// HomePage.js
import React from 'react';

function HomePage() {
  return (
    <div>
      <h1>Preview</h1>
      <p>Preview Tweet & more, Try now</p>
      <p><a href="https://framedpost.donalde.com/chooseastyle.html">Choose a Style > </a></p>

    </div>
  );
}

export default HomePage;
